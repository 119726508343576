<script setup>
import { transl, useTranslation } from "@/composables/useTranslation";

const { setLocale } = useTranslation();

let donateUrl = {
  uk: "https://angel.hope.ua/bibleschool_donate",
  ru: "https://angel.hope.ua/bibleschool_donate/ru",
  en: "https://angel.hope.ua/bibleschool_donate/en",
};
</script>
<template>
  <ClientOnly>
    <header>
      <div class="header-wrapper">
        <router-link to="/">
          <V-LogoSvg :options="{ width: '180px', color: 'black' }" />
        </router-link>
        <nav class="navigation-wrapper">
          <ul class="navigation">
            <router-link to="/"
              ><li class="navigation-item px-4 active">
                {{ transl("Texts:Home") }}
              </li></router-link
            >

            <router-link to="/#courses"
              ><li class="navigation-item px-4">
                {{ transl("Texts:Courses") }}
              </li></router-link>
            

            <router-link to="/#about"
              ><li class="navigation-item px-4">
                {{ transl("Texts:About us") }}
              </li></router-link
            >

            <router-link to="/#contacts"
              ><li class="navigation-item px-4">
                {{ transl("Texts:Contacts") }}
              </li></router-link
            >
          </ul>
        </nav>

        <a class="donate-wrapper" href="https://angel.hope.ua/bibleschool_donate"
          ><div class="donate">{{ transl("Text:To support") }}</div>
        </a>
        <ul class="lang">
          <v-select-language @change="(el) => setLocale(el)"></v-select-language>
        </ul>
        <a class="sign" href="https://bibleawr.com/login"
          ><div :title="transl('Texts:Login as teacher')">
            <img src="/icons/login.svg" alt="" /></div
        ></a>
        <div class="mobile-menu-wrapper" @click="emitGlobal('menu-left-mobile')">
          <img src="/icons/mobile-menu.svg" alt="" />
        </div>
      </div>
    </header>
  </ClientOnly>
</template>

<style scoped>
header {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.09), 0 2px 2px 0 rgba(0, 0, 0, 0.06),
    0 1px 5px 0 rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 5;
}
.header-wrapper {
  max-width: 1218px;
  min-width: 320px;
  padding: 0 15px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  align-items: center;
  gap: 30px;
  box-sizing: border-box;
  color: #333333;
}
.header-wrapper .donate-wrapper {
  text-decoration: none;
  margin-left: auto;
}
.logo {
  width: 180px;
  filter: brightness(0) saturate(100%) invert(9%) sepia(9%) saturate(495%)
    hue-rotate(149deg) brightness(96%) contrast(89%);
}

.navigation-wrapper {
  margin-left: auto;
  height: 50px;
  display: flex;
  align-items: center;
}
.navigation {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  height: 50px;
  align-items: center;
}
.navigation a {
  text-decoration: none;
  color: inherit;
}
.menu-items a {
  text-decoration: none;
  color: inherit;
}
.navigation-item {
  height: 40px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
}
.navigation-item.active {
  color: #ed9819;
}
.donate {
  padding: 10px 20px;
  background: linear-gradient(180deg, #ffb649, #e78f0c);
  border-radius: 50px;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}
.lang {
  display: flex;
}
.lang-item {
  font-size: 14px;
  font-weight: 600;
  padding: 13px 10px;
  position: relative;
}
.lang-item:before {
  content: "";
  position: absolute;
  height: 17px;
  width: 2px;
  background-color: #000;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.lang-item:nth-last-child(1):before {
  display: none;
}
.lang-item.active {
  color: #da8b15;
}
.sign {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign img {
  width: 25px;
  height: 30px;
}

.mobile-menu-wrapper {
  display: none;
  cursor: pointer;
}

@media (hover: hover) {
  .logo:hover {
    cursor: pointer;
  }
  .navigation-item:hover {
    cursor: pointer;
    background: rgb(0 0 0 / 10%);
    border-radius: 10px;
  }
  .donate:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .lang-item:hover {
    cursor: pointer;
    background-color: rgb(0 0 0 / 10%);
    border-radius: 10px;
  }
  .sign:hover {
    cursor: pointer;
    background-color: rgb(0 0 0 / 10%);
    border-radius: 10px;
  }
}

@media (hover: none) {
  .logo:active {
    cursor: pointer;
  }
  .navigation-item:active {
    cursor: pointer;
    background: rgb(0 0 0 / 10%);
    border-radius: 10px;
  }
  .donate:active {
    cursor: pointer;
    opacity: 0.8;
  }
  .lang-item:active {
    cursor: pointer;
    background-color: rgb(0 0 0 / 10%);
    border-radius: 10px;
  }
  .sign:active {
    cursor: pointer;
    background-color: rgb(0 0 0 / 10%);
    border-radius: 10px;
  }
}

@media (max-width: 1024px) {
  .navigation-wrapper,
  .sign {
    display: none;
  }
  .donate {
    margin-left: auto;
  }
  .mobile-menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
  }
  .mobile-menu-wrapper:hover {
    opacity: 0.8;
  }
  .mobile-menu {
    display: none;
  }
}
@media (max-width: 720px) {
  .lang {
    display: none;
  }
  .logo {
    width: 110px;
  }
  .header-wrapper {
    height: 50px;
    gap: 25px;
  }
}
</style>
~/composables/useTranslation
